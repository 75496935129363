<template>
    <div class="c_content_container contact">
        <!-- 상단배너 START -->
        <section class="c_section banner banner_contact">
            <div class="c_inner">
                <div class="img_wrapper contact">
                    <div class="icon_wrapper">
                        <!-- <img src="@/assets/images/banner/icon_contact.png" alt="아이콘" class="icon" /> -->
                    </div>
                </div>
            </div>
            <div class="c_inner">
                <div class="page_text">
                    <h2 v-textMotion>
                        <span class="textMotion_target">
                            <span class="text_deco"
                                >함께라면 멋진 일을 만들 수 있어요!</span
                            >
                        </span>
                    </h2>
                </div>
            </div>
        </section>
        <!-- 상단배너 END -->

        <section class="c_section contact_content">
            <div class="c_inner">
                <div class="l_company_info">
                    <dl>
                        <dt>
                            <img
                                src="@/assets/images/contact/icon_location.png"
                                alt="아이콘"
                            />
                        </dt>
                        <dd>경기도 고양시 일산동구 중앙로 1193, 6층</dd>
                    </dl>
                    <dl>
                        <dt>
                            <img
                                src="@/assets/images/contact/icon_email.png"
                                alt="아이콘"
                            />
                        </dt>
                        <dd>master@ownersite.co.kr</dd>
                    </dl>
                    <dl>
                        <dt>
                            <img
                                src="@/assets/images/contact/icon_tel.png"
                                alt="아이콘"
                            />
                        </dt>
                        <dd>0507-1387-6003</dd>
                    </dl>
                </div>
                <!-- <div class="time">
					<h2><img :src="time_icon" /><span>{{this.time}}</span></h2>
					<p>{{this.time_text}}</p>
				</div> -->
                <h2 v-textMotion>
                    <span class="textMotion_target"
                        >오늘의 이용현황이에요.</span
                    >
                </h2>
                <div
                    class="contact_num"
                    v-fadein:reverse.child="{ delay: 200 }"
                >
                    <div class="num_wrapper">
                        <h3 class="num_title">누적 방문자 수</h3>
                        <p>
                            <span class="count count01"
                                ><CountUp
                                    ref="count"
                                    :startVal="this.visit_count"
                                    :endVal="this.visit_count + 100"
                                    :duration="2"
                                    :autoplay="false"
                                    @init="onInit"
                                />명</span
                            >
                        </p>
                    </div>
                    <div class="num_wrapper">
                        <h3 class="num_title">입점사 수</h3>
                        <p>
                            <span class="count count02"
                                ><CountUp
                                    ref="count"
                                    :startVal="this.store_count"
                                    :endVal="this.store_count + 100"
                                    :duration="2"
                                    :autoplay="false"
                                    @init="onInit"
                                />개</span
                            >
                        </p>
                    </div>
                    <div class="num_wrapper">
                        <h3 class="num_title">창업 서비스 수</h3>
                        <p>
                            <span class="count count03"
                                ><CountUp
                                    ref="count"
                                    :startVal="this.founded_count"
                                    :endVal="this.founded_count + 100"
                                    :duration="2"
                                    :autoplay="false"
                                    @init="onInit" /></span
                            >개
                        </p>
                    </div>
                </div>

                <h2 v-textMotion>
                    <span class="textMotion_target"
                        >문의하실 유형을 선택해주세요.</span
                    >
                </h2>
                <ul
                    v-fadein:reverse.child="{ delay: 200 }"
                    class="contact_menu"
                >
                    <li
                        @click="this.$router.push('/contact/detail?which=견적')"
                    >
                        <div class="text_wrapper">
                            <p>비교 견적</p>
                        </div>
                        <div class="img_wrapper">
                            <img
                                src="@/assets/images/contact/img_esti.jpg"
                                alt="견적"
                            />
                        </div>
                    </li>
                    <li
                        @click="this.$router.push('/contact/detail?which=제휴')"
                    >
                        <div class="text_wrapper">
                            <p>제휴</p>
                        </div>
                        <div class="img_wrapper">
                            <img
                                src="@/assets/images/contact/img_partner.jpg"
                                alt="제휴"
                            />
                        </div>
                    </li>
                    <li
                        @click="this.$router.push('/contact/detail?which=제안')"
                    >
                        <div class="text_wrapper">
                            <p>제안</p>
                        </div>
                        <div class="img_wrapper">
                            <img
                                src="@/assets/images/contact/img_proposal.jpg"
                                alt="제안"
                            />
                        </div>
                    </li>
                    <li
                        @click="this.$router.push('/contact/detail?which=채용')"
                    >
                        <div class="text_wrapper">
                            <p>채용</p>
                        </div>
                        <div class="img_wrapper">
                            <img
                                src="@/assets/images/contact/img_incruit.jpg"
                                alt="채용"
                            />
                        </div>
                    </li>
                </ul>
            </div>
        </section>
    </div>
</template>

<script>
import CountUp from "vue-countup-v3";

export default {
    components: {
        CountUp,
    },
    computed: {
        isWorkTime() {
            const now = new Date();
            return now.getHours() >= 10 && now.getHours() <= 18;
        },
    },
    data() {
        return {
            time: "",
            time_icon: "",
            time_text: "",
            basic_date : '2022-07-14T14:08:31.000+00:00',
            visit_count : 563925,
            store_count : 125,
            founded_count : 3045
        };
    },
    mounted() {},
    created() {
        this.setTimeEl();
        this.intervalID = setInterval(() => {
            this.setTimeEl();
        }, 1000);

        const basic = new Date(this.basic_date.valueOf()).getTime();
        const now = new Date().getTime();
        const chai = now - basic;

        this.visit_count = this.visit_count + (Math.floor(chai / (1000 * 60 * 60 * 24)) * (225 + Math.floor(Math.random() * 11)));
        this.store_count = this.store_count + (Math.floor(chai / (1000 * 60 * 60 * 24 * 30)) * 11);
        this.founded_count = this.founded_count + (Math.floor(chai / (1000 * 60 * 60 * 24 * 30)) * 6);

    },
    unmounted() {
        clearInterval(this.intervalID);
    },
    methods: {
        setTimeEl() {
            this.time = this.getTimeFormat(new Date());
            (this.time_icon = this.isWorkTime
                ? require("@/assets/images/contact/icon_sun.png")
                : require("@/assets/images/contact/icon_moon.png")),
                (this.time_text = this.isWorkTime
                    ? "We are on Duty !"
                    : "We got off the Work !");
        },
        getTimeFormat(date) {
            return `${("0" + date.getHours()).slice(-2)} : ${(
                "0" + date.getMinutes()
            ).slice(-2)} : ${("0" + date.getSeconds()).slice(-2)}`;
        },
        onInit(count) {
            const loadingEl = document.querySelector(".contact_num");
            const observer = new IntersectionObserver(
                (entries, observer) => {
                    entries.forEach((entry) => {
                        if (entry.isIntersecting) {
                            count.start();
                            observer.unobserve(loadingEl);
                        }
                    });
                },
                {
                    root: null,
                    threshold: 0,
                }
            );
            observer.observe(loadingEl);
        },
    },
};
</script>

<style scoped src="@/assets/css/layout.css"></style>
<style scoped src="@/assets/css/contact.css"></style>
